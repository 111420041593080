<template>
  <div>
    <b-row>
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" lg="10">
        <b-row>
          <b-col sm="12" lg="12"><strong>Font Awesome</strong></b-col>
        </b-row>
        <b-row>
          <b-col sm="12" lg="3" v-for="(fa, fa_index) in d_faIcon" style="border: solid 1px grey;">
            <b-row>
              <b-col sm="12" lg="3">
                <i :class="'fa ' + fa" style="font-size: 20px;"></i>
              </b-col>
              <b-col sm="12" lg="9">
                {{ fa }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="12" lg="1"></b-col>
    </b-row>
  </div>
</template>

<script>
import { data as fa_icon } from '@/wam/options/fa_icon';
import { mapGetters } from 'vuex';
export default {
  name: 'icon_list',
  computed: mapGetters({
    StoreDevice: 'StoreDevice',
    StoreLang: 'StoreLang',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
  }),
  components: {},
  props: {},
  data () {
    return {
      d_faIcon: fa_icon,
    };
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {},
  watch: {}
};

</script>

<style type="text/css"> </style>

